<article class="project-card card--hover-black">
  <figure class="card__figure project-card__crop aspect-ratio" [class.no--image]="!project.headerImage">
    <!-- <img
      loading="lazy"
      class="project-card__image"
      *ngIf="project.headerImage"
      [src]="project.headerImage | resize: 824:623:project.imageAnchor"
      [alt]="project.title"
      
    /> -->
    <a [routerLink]="project.routerLink" class="text-decoration-none">
      <app-image
        [class.set--loaded]="isLoaded"
        *ngIf="project.headerImage"
        class="project-card__image"
        [src]="project.headerImage"
        loading="lazy"
        [alt]="project.title"
        (loaded)="isLoaded = $event"
        [anchor]="project.imageAnchor"
        [width]="824"
        [height]="623"
        [sizes]="{ sm: 2, md: 4, xl: 4 }"
        [imageSizes]="[300, 500, 600]"
      ></app-image>
    </a>
    <a [routerLink]="project.routerLink" class="button color--white button--border card__figure__read-more">View project</a>
  </figure>
  <a [routerLink]="project.routerLink" class="text-decoration-none title-link">
    <h1 class="h3 type-body-small project-card__title">{{ project.title }}</h1>
  </a>
</article>
