<article class="project-card project-card-small color-white">
  <figure class="project-card__crop project-card-small__crop">
    <img
      loading="lazy"
      class="project-card__image project-card-small__image"
      *ngIf="project.headerImage"
      [src]="project.headerImage | resize: 824:623:project.imageAnchor"
      [alt]="project.title"
      alt="mock image"
    />
  </figure>
  <h1 class="h3 type-body-small my-2 project-card-small__title">
    <span>{{ project.title }}</span>
    <span *ngIf="project.location">, {{ project.location }}</span>
  </h1>
  <a [routerLink]="project.routerLink" class="project__link block-link">{{ project.title }}</a>
</article>
